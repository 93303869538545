export default {
  colors: {
    text: '#070707',
    background: '#FAFAFF',
    backgroundShade1: '#ebebee',
    primary: '#1B70AD',
    secondary: '#389BE1',
    dark: '#222222',
    accent: '#494949'
  },
  buttons: {
    primary: {
      color: 'background',
      borderRadius: 3,
      padding: '8px 30px',
      bg: 'primary',
      border: '2px solid',
      borderColor: 'primary',
      fontSize: 1,
      fontWeight: 'heading',
      textDecoration: 'none',
      textTransform: 'uppercase',
      '&:hover': {
        opacity: 0.8,
        cursor: 'pointer'
      }
    },
    secondary: {
      color: 'primary',
      borderRadius: 3,
      padding: '8px 30px',
      bg: 'background',
      border: '2px solid',
      borderColor: 'primary',
      fontSize: 1,
      fontWeight: 'heading',
      textDecoration: 'none',
      textTransform: 'uppercase',
      '&:hover': {
        opacity: 0.8,
        cursor: 'pointer'
      }
    }
  },
  forms: {
    input: {
      borderRadius: 3,
      borderWidth: '2px',
      '&:focus': {
        outline: 'primary',
        borderColor: 'primary'
      }
    },
    select: {
      borderRadius: 3,
      borderWidth: '2px',
      '&:focus': {
        outline: 'primary',
        borderColor: 'primary'
      }
    },
    textarea: {
      borderRadius: 3,
      fontFamily: 'body',
      borderWidth: '2px',
      '&:focus': {
        outline: 'primary',
        borderColor: 'primary'
      }
    },
    label: {},
    radio: {},
    checkbox: {
      color: 'black'
    }
  },
  breakpoints: ['40em', '52em', '64em'],
  fonts: {
    body: 'Montserrat, system-ui, sans-serif',
    heading: 'Montserrat, system-ui, sans-serif',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [14, 16, 18, 20, 24, 28, 36, 48, 52, 60, 90],
  fontWeights: {
    body: 400,
    heading: 800,
    bold: 700
  },
  lineHeights: {
    body: 1.35,
    heading: 1.1,
    subheading: 1.25
  },
  text: {
    heroHeading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textTransform: 'uppercase',
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.8)',
      WebkitTextStrokeWidth: '1px',
      WebkitTextStrokeColor: 'rgba(0, 0, 0, .6)',
      fontSize: [6, 7, 7],
      my: 4
    },
    heroSubheading: {
      fontFamily: 'heading',
      lineHeight: 'subheading',
      fontWeight: 'heading',
      fontSize: [3, 3, 4],
      textTransform: 'uppercase',
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.8)',
      WebkitTextStrokeWidth: '1px',
      WebkitTextStrokeColor: 'rgba(0, 0, 0, .6)',
      mb: 4
    },
    giant: {
      fontSize: [7, 8, 9, 10],
      fontWeight: 900
    },
    label: {
      fontSize: 2,
      fontWeight: 700,
      textTransform: 'uppercase'
    },
    heroParagraph: {
      fontWeight: '600',
      textShadow: '0px 2px 2px rgba(0, 0, 0, 0.8)',
      WebkitTextStrokeWidth: '1px',
      WebkitTextStrokeColor: 'rgba(0, 0, 0, .4)',
      fontSize: [1, 1, 2]
    },
    heading: {
      fontSize: [4, 5, 7],
      mb: 2
    },
    subheading: {
      fontSize: [3, 4, 6],
      fontWeight: 300
    },
    cardHeading: {
      color: 'primary',
      fontSize: [3, 4],
      mb: 2
    },
    footerHeader: {
      fontSize: 3,
      mt: 0,
      mb: 2
    },
    body: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 1
    },
    caption: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 0
    },
    button: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 1
    },
    renderedMarkdown: {
      h1: {
        fontSize: [5, 6, 7],
        mb: 2
      },
      h2: {
        fontSize: [4, 5, 5],
        marginTop: 4,
        marginBottom: -2
      },
      h3: {
        fontSize: [3, 4, 4],
        marginTop: 4,
        marginBottom: -2
      },
      a: {
        color: 'primary'
      }
    }
  },
  link: {
    nav: {
      textDecoration: 'none',
      color: 'inherit',
      marginRight: [0, 0, 4],
      my: [3, 3, 0]
    },
    footer: {
      textDecoration: 'none',
      color: 'inherit'
    },
    primary: {
      textDecoration: 'none',
      color: 'primary',
      fontWeight: 600
    },
    secondary: {
      textDecoration: 'none',
      color: 'secondary',
      fontWeight: 600
    }
  },
  ul: {
    footer: {
      padding: 0
    }
  },
  li: {
    footer: {
      listStyleType: 'none',
      my: 3,
      color: 'backgroundShade1'
    }
  },
  hr: {
    primary: {
      width: '100%',
      backgroundColor: 'primary',
      border: 0,
      margin: 0,
      height: '2px',
      my: 4
    },
    secondary: {
      width: '100%',
      backgroundColor: 'secondary',
      border: 0,
      margin: 0,
      height: '2px',
      my: 4
    }
  },
  shadows: {
    card: '0px 4px 4px rgba(0, 0, 0, 0.25);'
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      backgroundColor: 'background'
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: ['heading'],
      fontSize: [4, 6, 7]
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [3, 5]
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [2, 4]
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontSize: [2, 3, 3],
      fontWeight: 'body',
      lineHeight: 'body',
      margin: 4
    },
    a: {
      color: 'primary',
      textDecoration: 'none',
      '&:visited': {
        color: 'primary'
      }
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    }
  },
  variants: {
    card: {
      textDecoration: 'none',
      color: 'inherit',
      borderRadius: 3,
      boxShadow: 'card',
      '&.hoverable:hover': {
        transform: 'translateY(-2px)',
        cursor: 'pointer',
        boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)'
      }
    }
  }
};
